import {
  Heading,
  Box,
  Text,
  Grid,
  Link,
  Image,
  useColorMode,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Capture from "assets/img/Capture.PNG";
import React from "react";

const AboutToolItem = ({ title, link, img }) => {
  const { colorMode } = useColorMode();
  return (
    <Card className="introduction-card">
      <Grid spacing={16} justify="flex-start">
        <Grid order={{ sm: "2", lg: "2" }}>
          <Link href={link}>
            <Heading as="h3" size="lg">
              {title}
            </Heading>
          </Link>
        </Grid>
        <Grid
          order={{ sm: "1", lg: "1" }}
          sx={{ display: { lg: "none", sm: "block" } }}
          mt="20px"
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap="24px"
          mb={{ sm: "26px" }}
        >
          <Link href={link}>
            <Image
              src={img}
              alt="img2"
              order={{ sm: "1", lg: "1" }}
              sx={{ display: { sm: "block", lg: "none" } }}
            />
          </Link>
        </Grid>
      </Grid>
      {/* <CardHeader>
        <Link href={link}><Heading as='h3' size='lg'>{title}</Heading></Link>
      </CardHeader> */}
      <CardBody mt={{ sm: "0px", lg: "30px" }}>
        <Grid
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap="24px"
          mb={{ lg: "26px" }}
        >
          <Link href={link}>
            <Image
              src={img}
              alt="img2"
              sx={{ display: { sm: "none", lg: "block" } }}
            />
          </Link>
          <Box fontSize="15px">
            <Text>
              Read the{" "}
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://nceph.anu.edu.au/research/projects/green-feeding-tool"
              >
                Brief
              </Link>
            </Text>
            <Text>
              Attend{" "}
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://nceph.anu.edu.au/research/projects/green-feeding-tool"
              >
                Webinar
              </Link>
            </Text>
            <Text>
              Read the{" "}
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://www.fhisolutions.org/innovating-to-improve-nutrition-can-help-meet-climate-targets/"
              >
                Commentary
              </Link>
            </Text>
            <Text>
              Follow{" "}
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://twitter.com/hashtag/GreenFeedingTool"
              >
                #GreenFeedingTool
              </Link>
            </Text>
            <Text>
              Download{" "}
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://www.aliveandthrive.org/sites/default/files/2023-06/Green-Feeding-Tool-Final.xlsm"
              >
                Offline version
              </Link>
            </Text>
            <Text>
              Read papers: <br />
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://www.frontiersin.org/articles/10.3389/fsufs.2023.1155279/abstract"
              >
                - Innovative financing for a gender-equitable first-food system
                to mitigate greenhouse gas impacts of commercial milk formula:
                Investing in breastfeeding as a carbon offset
              </Link>
              <br />
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://www.frontiersin.org/articles/10.3389/fnut.2024.1371036/full"
              >
                - Estimating carbon and water footprints associated with
                commercial milk formula production and use: development and
                implications of the Green Feeding Climate Action Tool
              </Link>
              <br />
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC11046154/pdf/BLT.23.290210.pdf"
              >
                - A proposal to recognize investment in breastfeeding as a
                carbon offset
              </Link>
              <br />
              <Link
                color={colorMode === "dark" ? "#89BD55" : "#89BD55"}
                target="_blank"
                href="https://www.ennonline.net/fex/72/human-milk-a-win-win-win-for-health-sustainability-and-economics"
              >
                - Human milk: A win-win-win for health, sustainability, and
                economics?
              </Link>
            </Text>
          </Box>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default AboutToolItem;
