// Chakra imports
import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Text,
  useColorModeValue,
  useColorMode,
  Grid,
  Image
} from "@chakra-ui/react";
import {
  LogoMT,
  DocumentIcon,
  HomeIcon,
  PersonIcon,
  RocketIcon,
} from "components/Icons/Icons";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import i18n from "../../locales/index";
import GGT from "./GGT";
import '../../style/printCss.css';
import logoMenu from "assets/img/LogoFull.png";
import logoLight from "assets/img/LogoFullLight.png";

export default function AuthNavbar(props) {
  const { colorMode, toggleColorMode } = useColorMode();
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const { logo, logoText, secondary, ...rest } = props;

  useEffect(()=>{
    activeRoute()
  }, [window.location.href])
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    if (routeName == '') {
      return window.location.href == window.location.origin + '/#/';
    }
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };

  const { t } = useTranslation();
  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
  }

  // Chakra color mode
  const logoNavBar = useColorModeValue(logoLight, logoMenu);
  let navbarIcon = useColorModeValue("gray.700", "gray.200");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let btnBgColor = useColorModeValue("gray.50", "#027776");
  let btnColor = useColorModeValue("gray.800", "gray.50");
  let navbarBg = useColorModeValue(
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  let navbarBorder = useColorModeValue(
    "1.5px solid #FFFFFF",
    "1.5px solid rgba(255, 255, 255, 0.31)"
  );
  let navbarShadow = useColorModeValue(
    "0px 7px 23px rgba(0, 0, 0, 0.05)",
    "none"
  );
  let navbarFilter = useColorModeValue(
    "none",
    "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
  );
  let navbarBackdrop = "blur(21px)";
  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  let navbarPosition = "absolute";
  let colorButton = "white";
  if (props.secondary === true) {
    navbarIcon = "white";
    navbarBg = "none";
    navbarBorder = "none";
    navbarShadow = "initial";
    navbarFilter = "initial";
    navbarBackdrop = "none";
    bgButton = "white";
    colorButton = "gray.700";
    mainText = "white";
    navbarPosition = "absolute";
  }
  var brand = (
    <Link
      href={`${process.env.PUBLIC_URL}/#/`}
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Image h="40px" src={logoNavBar} alt='logo' />
    </Link>
  );
  var linksAuth = (
    <HStack display={{ sm: "none", lg: "flex" }}>
      <NavLink className='nav-link' to={t("header.item1.to")}>
        <Button
          fontSize="sm"
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={activeRoute('') ? (colorMode === 'dark' ? '#89BD55' : '#89BD55') : navbarIcon}
          variant="transparentWithIcon"
        >
          <Text id="textBtnIntroduction" fontSize="1.1rem">{t("header.item1.value")}</Text>
        </Button>
      </NavLink>
      <NavLink className='nav-link' to={t("header.item2.to")}>
        <Button
          fontSize="sm"
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={activeRoute('/preloaded-data') ? (colorMode === 'dark' ? '#89BD55' : '#89BD55') : navbarIcon}
          variant="transparentWithIcon"
        >
          <Text id="textBtnCountryCalculator" fontSize="1.1rem">{t("header.item2.value")}</Text>
        </Button>
      </NavLink>
      <NavLink className='nav-link' to={t("header.item3.to")}>
        <Button
          fontSize="sm"
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={activeRoute('/data-entry') ? (colorMode === 'dark' ? '#89BD55' : '#89BD55') : navbarIcon}
          variant="transparentWithIcon"
        >
          <Text id="textBtnDataEntry" fontSize="1.1rem">{t("header.item3.value")}</Text>
        </Button>
      </NavLink>
      {/* <NavLink className='nav-link' to={t("header.item4.to")}>
        <Button
          fontSize="sm"
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={activeRoute('/feedback') ? (colorMode === 'dark' ? '#89BD55' : '#89BD55') : navbarIcon}
          variant="transparentWithIcon"
        >
          <Text id="textBtnFeedback" fontSize="1.1rem">{t("header.item4.value")}</Text>
        </Button>
      </NavLink> */}
    </HStack>
  );
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const googleTranslateElementInit = () => {
    new google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: google.translate.TranslateElement.InlineLayout.VERTICAL,
      },
      "google_translate_element"
    );
  };
  return (
    <Flex
      position={navbarPosition}
      top="16px"
      left="50%"
      transform="translate(-50%, 0px)"
      borderBottom={navbarBorder}
      borderTop={navbarBorder}
      boxShadow={navbarShadow}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderRadius="5px"
      px="16px"
      py="22px"
      mx="auto"
      width="100%"
      alignItems="center"
    >
      <Flex w="100%" justifyContent={{ sm: "space-between", lg: "space-between" }}>
        {brand}

        {linksAuth}
        <Flex>
          {/* <Button id="btnEN" bg={btnBgColor} color={i18n.language === 'en' ? "#89BD55":btnColor} onClick={() =>handleChangeLanguage("en")}>ENG</Button> */}
          {/* <Button id="btnFR" bg={btnBgColor} color={i18n.language === 'fr' ? "#89BD55":btnColor} onClick={() =>handleChangeLanguage("fr")}>FR</Button> */}
          <Box id="btnOT" borderRadius="10px" pl="5px" bg={btnBgColor} color={i18n.language === 'ot' ? "#89BD55" : btnColor} ><Box id="google_translate_element" /></Box>

          <Button id="btntoggleColorMode" onClick={toggleColorMode}
            margin="0px 5px"
            borderRadius="10px"
            bg={btnBgColor}
            color={btnColor}>
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </Button>
          <Box
            ms={{ base: "auto", lg: "0px" }}
            display={{ base: "flex", lg: "none" }}
          >
            <SidebarResponsive
              logoText={props.logoText}
              secondary={props.secondary}
              routes={routes}
              {...rest}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
