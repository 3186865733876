import { INIT_STATE } from "../../constants.js";
import { getCountryInfo, getType} from "../actions";

export default function countryInfoReducer(state = INIT_STATE.countryInfo, action) {
  switch (action.type) {
    case getType(getCountryInfo.getCountryInfoRequest):
      return {
        ...state,
        isHideDataInfo: true
      };
    case getType(getCountryInfo.getCountryInfoSuccess):
      return {
        ...state,
        data: action.payload,
        isHideDataInfo: false
      };
    case getType(getCountryInfo.getCountryInfoError):
      return {
        ...state,
      };  
    default:
      return state;
  }
}
