import { Heading, Box, Link, Text, Grid, Center, List, ListItem, ListIcon, OrderedList, UnorderedList, useColorMode, Image } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";

const ExperienceAndperspective = ({ title, link, img = '' }) => {
  const { colorMode } = useColorMode();
  return (
    <Card className="introduction-card">
      <Grid spacing={16} justify="flex-start">
        <Grid order={{ sm: "2", lg: "2" }}>
          <Link href={link}>
            <Heading as='h3' size='lg'>{title}</Heading>
          </Link>
        </Grid>
        <Grid
          order={{ sm: "1", lg: "1" }}
          sx={{ display: { lg: 'none', sm: 'block' } }}
          mt="20px"
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'
          mb={{ sm: "26px" }}>
          <Link href={link}>
            <Image src={img} alt='img2' order={{ sm: "1", lg: "1" }} sx={{ display: { sm: 'block', lg: 'none' } }} />
          </Link>
        </Grid>
      </Grid>
      {/* <CardHeader>
        <Link href={link}>
          <Heading as='h3' size='lg'>{title}</Heading>
        </Link>
      </CardHeader> */}
      <CardBody mt={{ sm: "0px", lg: "30px" }}>
        <Grid
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'
          mb={{ lg: "26px" }}>
          <Link href={link}>
            <Image src={img} alt='img2' sx={{ display: { sm: 'none', lg: 'block' } }} />
          </Link>
          <Box fontSize="15px">
            <Text>
              <Link color={colorMode === 'dark' ? '#89BD55' : '#89BD55'} target="_blank" href='https://forms.office.com/Pages/ResponsePage.aspx?id=32quJr5hP0SNxGUxthqaOKNuX5OvLmZOssjGlr4FJL1URUozVEJTNEY3R0VTNkExR0E2RkNGSllXSy4u'>Give your feedback</Link>
            </Text>
            <Text>
              <Link color={colorMode === 'dark' ? '#89BD55' : '#89BD55'} target="_blank" href='https://forms.office.com/Pages/AnalysisPage.aspx?AnalyzerToken=jGFDKWyWpWTYrssfOP7fl2JWoFwQGy3J&id=32quJr5hP0SNxGUxthqaOKNuX5OvLmZOssjGlr4FJL1URUozVEJTNEY3R0VTNkExR0E2RkNGSllXSy4u'>Feedback from other users</Link>
            </Text>
            <Text>
              <Link color={colorMode === 'dark' ? '#89BD55' : '#89BD55'} target="_blank" href='https://www.aliveandthrive.org/en/contact-us'>Contact us</Link>
            </Text>
          </Box>
          <Grid>
          </Grid>
        </Grid>
      </CardBody>
    </Card>

  );
};

export default ExperienceAndperspective;
