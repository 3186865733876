import CountryCalculator from "views/GreenFeedingTool/CountryCalculator";
import OtherData from "views/GreenFeedingTool/OtherData";
import Feedback from "views/Introduction/Feedback";
import Introduction from "views/Introduction/Introduction";

var dashRoutes = [
  {
    name: "INTRODUCTION PAGES",
    category: "category",
    state: "pageCollapse",
    views: [
      {
        path: "/introduction",
        name: "Introduction",
        component: Introduction,
        layout: "",
      },
      // {
      //   path: "/feedback",
      //   name: "Feedback",
      //   component: Feedback,
      //   layout: "",
      // },
      {
        path: "/preloaded-data",
        name: "Calculate",
        component: CountryCalculator,
        layout: "",
      },
      {
        path: "/data-entry",
        name: "Use own data",
        component: OtherData,
        layout: "",
      },
      {
        path: "/*",
        name: "Introduction",
        component: Introduction,
        layout: "",
      }
    ],
  }
];
export default dashRoutes;
