export const countryState$ = (state) => state.countries?.data;
export const hideDataCountry$ = (state) => state.countries?.isHide;
export const hideOtherDataCountry$ = (state) => state.countries?.isHideOtherData;
export const hideViewTable$ = (state) => state.countries?.isHideViewTable;
export const ownData$ = (state) => state.countries?.ownData;
export const counterfactorData$ = (state) => state.countries?.counterfactorData;
export const loadingDataCountry$ = (state) => state.countries?.isLoading;
export const isDisableBtnSearch$ = (state) => state.countries?.isDisableBtnSearch;

export const countryInfoState$ = (state) => state.countryInfoReducer?.data;
export const isHideDataInfo$ = (state) => state.countryInfoReducer?.isHideDataInfo;