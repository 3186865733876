/*eslint-disable*/
import { HamburgerIcon } from "@chakra-ui/icons";
// chakra imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import logo from "assets/img/LogoFull.png";
import logoLight from "assets/img/LogoFullLight.png"
import { Separator } from "components/Separator/Separator";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

function SidebarResponsive(props) {
  const { colorMode } = useColorMode();

  // to check for active links and opened collapses
  let location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    if(routeName == '') {
      return window.location.href == window.location.origin + '/#/';
    }
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };

  const createLinks = (routes) => {
    // Chakra Color Mode
    const bg = useColorModeValue("white", "#027776");
    //const inactiveBg = useColorModeValue("white", "#027776");
    const activeColor = useColorModeValue("#027776", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");

    const { t } = useTranslation();
    const navbarItem = (to, value) => {
      return (
        <NavLink to={to}>
          <Button
            onClick={onClose}
            boxSize="initial"
            justifyContent="flex-start"
            alignItems="center"
            bg={bg}
            mb={{
              xl: "12px",
            }}
            mx={{
              xl: "auto",
            }}
            ps={{
              sm: "10px",
              xl: "16px",
            }}
            py="12px"
            borderRadius="15px"
            _hover={{}}
            w="100%"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}>
            <Flex>
              <Text color={activeRoute(to) ? (colorMode === 'dark' ? '#89BD55' : '#89BD55') : activeColor} my="auto" fontSize="sm">
                {value}
              </Text>
            </Flex>
          </Button>
        </NavLink>
      );
    }
    return (
      <Box>
        {navbarItem(t("header.item1.to"), (t("header.item1.value")))}
        {navbarItem(t("header.item2.to"), (t("header.item2.value")))}
        {navbarItem(t("header.item3.to"), (t("header.item3.value")))}
        {/* {navbarItem(t("header.item4.to"), (t("header.item4.value")))} */}
      </Box>
    )
  };

  const { logoText, routes, ...rest } = props;
  const logoNavBar = useColorModeValue(logoLight, logo);

  const bg = useColorModeValue("white", "#027776");

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let hamburgerColor = useColorModeValue("gray.500", "gray.200");
  if (props.secondary === true) {
    hamburgerColor = "white";
  }
  var brand = (
    <Box pt={"35px"} mb="8px">
      <Link
        href={`${process.env.PUBLIC_URL}/#/`}
        target="_blank"
        display="flex"
        lineHeight="100%"
        mb="30px"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        fontSize="11px"
      >
        <Image h="32px" src={logoNavBar} alt='logo' />

      </Link>
      <Separator></Separator>
    </Box>
  );

  // SIDEBAR

  const btnRef = React.useRef();
  // Color variables
  return (
    <Flex
      display={{ sm: "flex", xl: "none" }}
      ref={mainPanel}
      alignItems="center"
    >
      <HamburgerIcon
        id="iconHamburgerIcon"
        color={hamburgerColor}
        w="18px"
        h="18px"
        ref={btnRef}
        //colorScheme="teal"
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="250px"
          maxW="250px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          borderRadius="16px"
        >
          <DrawerCloseButton
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="250px" px="1rem" backgroundColor={bg}>
            <Box maxW="100%" h="100vh">
              <Box>{brand}</Box>
              <Stack direction="column" mb="40px">
                <Box>{links}</Box>
              </Stack>
              {/* <SidebarHelp></SidebarHelp> */}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default SidebarResponsive