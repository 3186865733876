import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../../api/index.js';
import * as actions from '../actions';

function* fetchCountrySaga() {
    try {
        const countries = yield call(api.getCountries);
        yield put(actions.getCountries.getCountrySuccess(countries.data));
    } catch (err) {
        console.log(err)
        yield put(actions.getCountries.getCountryError(err));
    }
}

function* fetchCountryInfoSaga(action) {
    try {
        const countryInfo = yield call(api.getCountryInfo, action.payload);
        yield put(actions.getCountryInfo.getCountryInfoSuccess(countryInfo.data));
    } catch (err) {
        console.log(err)
        yield put(actions.getCountryInfo.getCountryInfoError(err));
    }
}

function* mySaga() {
    yield takeLatest(actions.getCountries.getCountryRequest, fetchCountrySaga)
    yield takeLatest(actions.getCountryInfo.getCountryInfoRequest, fetchCountryInfoSaga)
}

export default mySaga;