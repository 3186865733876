// Chakra imports
import {
  Center,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import { useSelector } from 'react-redux';
import { counterfactorData$, ownData$ } from 'redux/selectors';
import { calculateEntryData, financial } from 'variables/calculator';
import React from 'react';
import { financialReturn0 } from 'variables/calculator';
import { useEffect } from 'react';

const TableCommon = ({ captions, data, rows, type = 1 }) => {
  const counterfactorData = useSelector(counterfactorData$);
  const ownData = useSelector(ownData$);
  const dataCalculated = calculateEntryData(ownData, counterfactorData);
  const textColor = useColorModeValue("#89BD55", "#89BD55");
  const borderColor = useColorModeValue("#edf2f7", "#1f2733");

  const isReturn0O = () => {
    return Math.round((Number)(ownData.ex) + (Number)(ownData.pb) + (Number)(ownData.nb)) == 100 ? true : false;
  }

  const isReturn0C = () => {
    return Math.round((Number)(counterfactorData.ex) + (Number)(counterfactorData.pb) + (Number)(counterfactorData.nb)) == 100 ? true : false;
  }

  const isReturn0Cacbon = () => {
    return Math.round((Number)(ownData.ex)) == 100 && Math.round((Number)(counterfactorData.ex)) == 100;
  }

  return (
    <Card className='table-country-caculator' overflowX={{ sm: "scroll", xl: "hidden" }} mb="24px">
      <Table variant='simple' mb="20px">
        <Thead>
          <Tr>
            {type == 1 && captions.map((caption, idx) => {
              return (
                <Th key={idx} pl={idx == 0 ? '0px' : null} textTransform="none" textAlign="center" width={"25%"} minWidth={'180px'}>
                  <Center><Text fontSize="14px" color={textColor} >{caption.title}</Text></Center>
                  <Center><Text fontSize="14px" color={textColor} >{caption.note}</Text></Center>
                  {/* <Center height='16px'><Text fontSize="12px" fontWeight="light"> {caption.note}</Text></Center> */}
                </Th>
              );
            })}
            {
              type != 1 && (
                captions.map((caption, idx) => {
                  return (
                    idx !== 2 ? (
                      <Th key={idx} width={"15%"} textTransform="none">
                        <Center>
                          <Text fontSize="14px" color={textColor} textAlign={"center"} >{caption.title}</Text>
                        </Center>
                        <Center>
                          <Text fontSize="14px" color={textColor} textAlign={"center"} >{caption.note}</Text>
                        </Center>
                        {/* <Center height='16px'><Text fontSize="12px" fontWeight="light"> {caption.note}</Text></Center> */}
                      </Th>
                    ) : (
                      <Th width={"35%"} key={idx} textTransform="none">
                        <Text fontSize="14px" color={textColor} textAlign={"center"} pb={"10px"} borderBottom={`1px solid ${borderColor}`} >{caption.bigTitle}</Text>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th textTransform="none" borderBottom={"unset"}>
                                <Text fontSize="14px" color={textColor} textAlign={"center"} >{caption.title1}</Text></Th>
                              <Th textTransform="none" borderBottom={"unset"} textAlign={"center"}><Text fontSize="14px" color={textColor} >{caption.title2}</Text></Th>
                            </Tr>
                          </Thead>
                        </Table>
                      </Th>
                    ));
                })
              )
            }
          </Tr>
        </Thead>
        <Tbody>
          {type == 1 ?
            (data && (
              <>
                <Tr fontSize="14px">
                  <Td pl="0px" textAlign={"center"} width={"25%"} minWidth={'180px'}><Center>{rows[0]}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{financial(data.eBF['month_0_6'] * 100, 1, '%')}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{financial(data.partialBF['month_0_6'] * 100, 1, '%')}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{financial(data.notBF['month_0_6'] * 100, 1, '%')}</Center></Td>
                </Tr>
                <Tr fontSize="14px">
                  <Td pl="0px" width={"25%"} minWidth={'180px'}><Center>{rows[1]}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0O() ? financialReturn0(ownData.ex, 1, '%') : financial(ownData.ex, 1, '%')}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0O() ? financialReturn0(ownData.pb, 1, '%') : financial(ownData.pb, 1, '%')}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0O() ? financialReturn0(ownData.nb, 1, '%') : financial(ownData.nb, 1, '%')}</Center></Td>
                </Tr>
                <Tr fontSize="14px">
                  <Td pl="0px" width={"25%"} minWidth={'180px'}><Center>{rows[2]}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0C() ? financialReturn0(counterfactorData.ex, 1, '%') : financial(counterfactorData.ex, 1, '%')}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0C() ? financialReturn0(counterfactorData.pb, 1, '%') : financial(counterfactorData.pb, 1, '%')}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0C() ? financialReturn0(counterfactorData.nb, 1, '%') : financial(counterfactorData.nb, 1, '%')}</Center></Td>
                </Tr>
              </>
            ))
            : (data && (
              <>
                <Tr fontSize="14px">
                  <Td pl="0px" width={"15%"} minWidth={'180px'} whiteSpace="nowrap"><Center>{rows[0]}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{financial(data.lostMilk['month_0_6'], 1)}</Center></Td>
                  <Td padding={"unset"}>
                    <Table>
                      <Tbody>
                        <Tr>
                          <Td borderBottom={"unset"} width={"15%"}><Center>{financial(data.lowCFP['month_0_6'], 1)}</Center></Td>
                          <Td borderBottom={"unset"} width={"15%"}><Center>{financial(data.highCFP['month_0_6'], 1)}</Center></Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{financial(data.amountWater['month_0_6'], 1)}</Center></Td>
                </Tr>
                <Tr fontSize="14px">
                  <Td pl="0px" width={"15%"} minWidth={'180px'} whiteSpace="nowrap"><Center>{rows[1]}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'} ><Center>{isReturn0Cacbon() ? financialReturn0(dataCalculated.lostMilkOwnData, 1) : financial(dataCalculated.lostMilkOwnData, 1)}</Center></Td>
                  <Td padding={"unset"}>
                    <Table>
                      <Tbody>
                        <Tr>
                          <Td borderBottom={"unset"} width={"15%"} minWidth={'180px'}><Center>{isReturn0Cacbon() ? financialReturn0(dataCalculated.lowCFPOwnData, 1) : financial(dataCalculated.lowCFPOwnData, 1)}</Center></Td>
                          <Td borderBottom={"unset"} width={"15%"} minWidth={'180px'}><Center>{isReturn0Cacbon() ? financialReturn0(dataCalculated.highCFPOwnData, 1) : financial(dataCalculated.highCFPOwnData, 1)}</Center></Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0Cacbon() ? financialReturn0(dataCalculated.amountWaterOwnData, 1) : financial(dataCalculated.amountWaterOwnData, 1)}</Center></Td>
                </Tr>
                <Tr fontSize="14px">
                  <Td pl="0px" width={"15%"} whiteSpace="nowrap" minWidth={'180px'}><Center>{rows[2]}</Center></Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0Cacbon() ? financialReturn0(dataCalculated.lostMilkCounterfactorData, 1) : financial(dataCalculated.lostMilkCounterfactorData, 1)}</Center></Td>
                  <Td padding={"unset"}>
                    <Table>
                      <Tbody>
                        <Tr>
                          <Td borderBottom={"unset"} width={"15%"}><Center>{isReturn0Cacbon() ? financialReturn0(dataCalculated.lowCFPCounterfactorData, 1) : financial(dataCalculated.lowCFPCounterfactorData, 1)}</Center></Td>
                          <Td borderBottom={"unset"} width={"15%"}><Center>{isReturn0Cacbon() ? financialReturn0(dataCalculated.highCFPCounterfactorData, 1) : financial(dataCalculated.highCFPCounterfactorData, 1)}</Center></Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Td>
                  <Td width={"25%"} minWidth={'180px'}><Center>{isReturn0Cacbon() ? financialReturn0(dataCalculated.amountWaterCounterfactorData, 1) : financial(dataCalculated.amountWaterCounterfactorData, 1)}</Center></Td>
                </Tr>
              </>
            )
            )
          }
        </Tbody>
      </Table>
    </Card >
  );
};

export default TableCommon;
