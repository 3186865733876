// Chakra imports
import {
  Center,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/actions';
import { countryInfoState$, countryState$, hideDataCountry$ } from '../../../redux/selectors';
import { calCacbonfootprint } from '../../../variables/calculator';
import { cacbonFootPrintTitle, countryInforColumn, countryInformationTitle } from '../../../variables/general';
import IndividualCountry from './components/CountryCalculator';
import TableCommon from './components/ProductionAndValue';
import SelectCountry from "./components/SelectCountry";

export default function Dashboard() {
  const textColor = useColorModeValue("#89BD55", "#89BD55");
  const dispatch = useDispatch();
  const countries = useSelector(countryState$);
  const hideDataCountry = useSelector(hideDataCountry$);

  const countryInfo = useSelector(countryInfoState$);
  let cacbonFootPrintData = {};

  React.useEffect(() => {
    dispatch(action.getCountries.getCountryRequest());
    if (countryInfo) {
      cacbonFootPrintData = calCacbonfootprint(countryInfo);
    }
  }, [dispatch, countryInfo]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "100px" }}>
      <IndividualCountry title={'Calculate'} />

      <Grid
        templateColumns={{ sm: "1fr", lg: "repeat(2, 1fr)" }}
        gap='24px'
      >
        <SelectCountry
          country={countries}
          selected={countryInfo?.isocode} />
      </Grid>

      {(hideDataCountry !== false && !!cacbonFootPrintData) &&
        <Grid mt="24px">
          <Text p='22px' fontSize='lg' fontWeight="bold">Breastfeeding practices among infants aged under 6 months {countryInfo?.source && countryInfo?.dataYear ? `(${countryInfo.source}, ${countryInfo.dataYear})` : (countryInfo.dataYear ? `(${countryInfo.dataYear})` : (countryInfo.source ? `(${countryInfo.source})` : ''))} </Text>
          <TableCommon
            captions={countryInformationTitle}
            data={calCacbonfootprint(countryInfo)}
            rows={countryInforColumn}
          />
          <Text p='22px' fontSize='lg' fontWeight="bold">Carbon and water footprints associated with consumption
            of commercial milk formula among infants aged under 6 months</Text>
          {
            (calCacbonfootprint(countryInfo).eBF.month_0_6 != 0) ?
              <TableCommon
                captions={cacbonFootPrintTitle}
                data={calCacbonfootprint(countryInfo)}
                rows={countryInforColumn}
                type={2}
              /> : <Center>
                <Text p='14px' color={textColor}>Preloaded data not available, please input other data</Text>
              </Center>
          }
        </Grid>
      }
    </Flex>
  );
}

// function countData(data) {
//   if (data == null || data == undefined) return 0;
//   let count = 0;
//   data[0]?.data?.forEach(element => {
//     if (element != 0) {
//       count++;
//     }
//   });
//   return count;
// }