// Custom icons

export const countryInformationTitle = [
  {
    title: "Age in months",
  },
  {
    title: "Exclusive or predominant breastfeeding",
  },
  {
    title: "Partial breastfeeding",
  },
  {
    title: "Not breastfed",
  },
  {
    title: "No. of infants",
  }
];

export const dataEntryTitle = [
  {
    title: "Scenario",
  },
  {
    title: "Preloaded data",
  },
  {
    title: "Own data",
  },
  {
    title: "Counterfactual data"
  }
];
export const viewDataEntryTitle = [
  {
    title: "Scenario",
  },
  {
    title: "Exclusive or predominant breastfeeding",
  },
  {
    title: "Partial breastfeeding",
  },
  {
    title: "Not breastfed"
  }
];

export const viewDataEntryTitle1 = [
  {
    title: "Scenario",
  },
  {
    title: "Exclusive or predominant breastfeeding",
  },
  {
    title: "Partial breastfeeding",
  },
  {
    title: "Not breastfed"
  },
  {
    title: "Number of livebirths"
  }
];

export const dataEntryRow = ["Preloaded data", "Own data", "Counterfactual data"];

export const cacbonFootPrintTitle = [
  {
    title: "Age in months",
  },
  {
    title: "Lost milk",
    note: "(Million liters)",
  },
  {
    bigTitle: "Carbon footprint (CO2 eq., million kg)",
    title1: "Lower estimation",
    title2: "Upper estimation",
  },
  {
    title: "Water footprint",
    note: "(Million liters)"
  }
];

export const viewCacbonFootPrintTitle = [
  {
    title: "Scenario",
  },
  {
    title: "Lost milk",
    note: "(Million liters)",
  },
  {
    bigTitle: "Carbon footprint (CO2 eq., million kg)",
    title1: "Lower estimation",
    title2: "Upper estimation",
  },
  {
    title: "Water footprint",
    note: "(Million liters)"
  }
];

export const countryInforColumn = ["0 –1.9", "2 – 3.9", "4 – 5.9", "Overall"];

export const dataEntryColumn = ["Exclusive or predominant breastfeeding", "Partial breastfeeding", "Not breastfed", "Number of live births"];

export const countryCalculatorOverViewF = "This calculator estimates the volume and value of breastmilk produced each year and the loss due to suboptimal breastfeeding practices in each country and globally.";
export const countryCalculatorOverViewL = "Potential users of this tool are policymakers, influencers, project managers, researchers, national accountants, statisticians, caregivers, and other persons supporting breastfeeding.";
export const newIntroductionOverViewF = "The Green Feeding Tool estimates carbon and water footprints associated with consumption of commercial milk formula among infants aged under 6 months.";
export const newIntroductionOverViewL = "Potential users of this tool are policymakers, influencers, project managers, environmentalists, researchers, national accountants, statisticians, and other persons supporting breastfeeding.";
export const calculationOverView = "This estimation uses preloaded data from countries that have information on breastfeeding practices for infants aged under 6 months.​"
export const otherDataOverView = "This estimation uses other information on breastfeeding practices or the number of infants aged under 6 months:" 