import React, { useState, useEffect } from "react";
import Poll from "react-polls";
//import Iframe from './components/iframe.js';
// import Iframe from 'react-iframe'
import { Heading, TabList, TabPanels, Tab, TabPanel, Box, Hide, Center, Grid, Link, useColorMode } from '@chakra-ui/react'
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
const Fakepolls = () => {
  const { colorMode } = useColorMode();

  return (
    <Card mt="100px">
      <CardHeader>
        <Heading as='h4' size='md' mb="5px">
          Feedback
        </Heading>
      </CardHeader>
      <CardBody>
        <Grid>
          <Link color={colorMode === 'dark' ? '#89BD55' : '#89BD55'} target="_blank" href='https://forms.office.com/Pages/ResponsePage.aspx?id=32quJr5hP0SNxGUxthqaOKNuX5OvLmZOssjGlr4FJL1URFFBQlhWOE0zMjBJOTk0MFU2UTFSR0g3Mi4u'>Give your feedback</Link>
          <Link color={colorMode === 'dark' ? '#89BD55' : '#89BD55'} target="_blank" href="https://forms.office.com/Pages/AnalysisPage.aspx?AnalyzerToken=4FVJcKKaXYgkKuiXNN4wef12iEza0ILV&id=32quJr5hP0SNxGUxthqaOKNuX5OvLmZOssjGlr4FJL1URFFBQlhWOE0zMjBJOTk0MFU2UTFSR0g3Mi4u">Feedback from other users</Link>
          <Link color={colorMode === 'dark' ? '#89BD55' : '#89BD55'} target="_blank" href='https://www.aliveandthrive.org/en/contact-us'>Contact us</Link>
        </Grid>
      </CardBody>
    </Card>
  );

};
export default Fakepolls;

class IFFeedback extends React.Component {
  render() {
    return <div id="ff-feedback"></div>;
  }
  componentDidMount() {
    var script = document.createElement("script");
    script.id = "ff-script-feedback";
    script.src = "https://formfacade.com/include/116534090482997173125/form/1FAIpQLSe8w1cX4V-k-eCYIqLm7mXZ0e_tu5SH8Ij4uGBVbFipuvhQBw/classic.js?div=ff-feedback";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);
  }
}