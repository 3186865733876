// Chakra imports
import {
  Button,
  Center,
  Grid,
  Input,
  NumberInput,
  NumberInputField,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { financial } from 'variables/calculator';
import * as action from '../../../../redux/actions';

const DataEntry = ({ captions, data, rows }) => {
  const dispatch = useDispatch();

  const [touched, setTouched] = React.useState(false)
  const onBlur = () => setTouched(true)

  const [ownData, setOwnData] = useState({
    ex: null,
    pb: null,
    nb: null,
    liveBirth: data.noOfLivebirths ?? 0
  });

  const [counterData, setCounterData] = useState({
    ex: null,
    pb: null,
    nb: null,
    liveBirth: data.noOfLivebirths ?? 0
  });

  useEffect(() => {
    setOwnData({ ...ownData, liveBirth: data.noOfLivebirths ?? 0 });
    setCounterData({ ...counterData, liveBirth: data.noOfLivebirths ?? 0 });
  }, [data.noOfLivebirths])

  const showTable = () => {
    dispatch(action.addOwnData(ownData));
    dispatch(action.counterfactorData(counterData));
    dispatch(action.isShowViewTable());
  }

  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  }

  const isValidTable = () => {
    const isOwnData = Math.round(((Number)(ownData.ex) + (Number)(ownData.pb) + (Number)(ownData.nb)));
    const isCounterData = Math.round(((Number)(counterData.ex) + (Number)(counterData.pb) + (Number)(counterData.nb)));
    return (isOwnData == 0 && isCounterData == 100) || (isCounterData == 0 && isOwnData == 100) || (isCounterData == 100 && isOwnData == 100);
  }

  const textColor = useColorModeValue("#89BD55", "#89BD55");

  return (
    <Card className='table-country-caculator' overflowX={{ sm: "scroll", xl: "hidden" }} mb="24px">
      <Text fontSize='15px' color='tomato'>
        {
          (isValidTable() || !touched) ? '' : <span key="unique">
            *Total own data must sum to 100%<br></br>
            *Total counterfactual data must sum to 100%
          </span>
        }
      </Text>
      <Table variant='simple' mb="20px">
        <Thead>
          <Tr>
            {captions.map((caption, idx) => {
              return (
                <Th key={idx} pl={idx == 0 ? '0px' : null} textTransform="none" textAlign="center" minWidth={'180px'} >
                  <Center><Text fontSize="14px" color={textColor} >{caption.title}</Text></Center>
                  <Center><Text fontSize="14px" color={textColor} >{caption.note}</Text></Center>
                  {/* <Center height='16px'><Text fontSize="12px" fontWeight="light"> {caption.note}</Text></Center> */}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody id="#formArea">
          {
            data && (
              <>
                <Tr fontSize="14px">
                  <Td pl="0px" textAlign={"center"} width={"20%"} minWidth={'180px'}><Center>{rows[0]}</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center>{financial(data.eBF['month_0_6'] * 100, 1, '%')}</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center>{financial(data.partialBF['month_0_6'] * 100, 1, '%')}</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center>{financial(data.notBF['month_0_6'] * 100, 1, '%')}</Center></Td>
                  <Td><Center>{financial(data.noOfLivebirths)}</Center></Td>
                </Tr>
                <Tr fontSize="14px">
                  <Td pl="0px" textAlign={"center"} width={"20%"} minWidth={'180px'}><Center>{rows[1]}</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center><Input textAlign={'center'} type='number' onBlur={onBlur} size='sm' defaultValue={ownData.ex} onChange={(e) => setOwnData({ ...ownData, ex: e.target.value })} />%</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center><Input textAlign={'center'} type='number' onBlur={onBlur} size='sm' defaultValue={ownData.pb} onChange={(e) => setOwnData({ ...ownData, pb: e.target.value })} />%</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center><Input textAlign={'center'}  type='number' onBlur={onBlur} size='sm' defaultValue={ownData.nb} onChange={(e) => setOwnData({ ...ownData, nb: e.target.value })} />%</Center></Td>
                  <Td><Center><Input type='text'  textAlign={'center'}  onBlur={onBlur} size='sm' value={commify(ownData.liveBirth)} onChange={(e) => setOwnData({ ...ownData, liveBirth: e.target.value.replace(/,/g, "") })} /></Center></Td>
                </Tr>
                <Tr fontSize="14px" >
                  <Td pl="0px" textAlign={"center"} width={"20%"} minWidth={'180px'}><Center>{rows[2]}</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center><Input textAlign={'center'} type='number' onBlur={onBlur} size='sm' defaultValue={counterData.ex} onChange={(e) => setCounterData({ ...counterData, ex: e.target.value })} />%</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center><Input textAlign={'center'} type='number' onBlur={onBlur} size='sm' defaultValue={counterData.pb} onChange={(e) => setCounterData({ ...counterData, pb: e.target.value })} />%</Center></Td>
                  <Td width={"20%"} minWidth={'180px'}><Center><Input textAlign={'center'} type='number' onBlur={onBlur} size='sm' defaultValue={counterData.nb} onChange={(e) => setCounterData({ ...counterData, nb: e.target.value })} />%</Center></Td>
                  {/* <Td><Center><Input type='number' onBlur={onBlur} size='sm' value={counterData.liveBirth} onChange={(e) => setCounterData({ ...counterData, liveBirth: e.target.value })} /></Center></Td> */}
                  <Td><Center><Input type='text' textAlign={'center'} onBlur={onBlur} size='sm' value={commify(counterData.liveBirth)} onChange={(e) => setCounterData({ ...counterData, liveBirth: e.target.value.replace(/,/g, "") })} /></Center>
                    {/* <NumberInput width={16} size={'xs'} ><NumberInputField /></NumberInput> */}
                  </Td>
                </Tr>
              </>
            )
          }
        </Tbody>
      </Table>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1fr" }}
        templateRows={{ sm: "1fr", lg: "1fr" }}
        gap='24px'>
        <Center>
          <Button
            disabled={!isValidTable()}
            color="#FFFFFF"
            background='#89BD55'
            opacity="0.75"
            _hover={{ background: "#89BD55", color: "#FFFFFF", opacity: 1 }}
            minW='110px'
            h='40px'
            fontSize='xs'
            px='1.5rem'
            onClick={() => showTable()}
            borderRadius="5px">
            <Text m="0px 10px">Calculate</Text>
          </Button>
        </Center>
      </Grid>
    </Card>
  );
};

export default DataEntry;
