// Chakra imports
import {
  Flex,
  Grid,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { dataEntryColumn, dataEntryRow, dataEntryTitle, viewCacbonFootPrintTitle, viewDataEntryTitle } from 'variables/general';
import * as action from '../../../redux/actions';
import { countryInfoState$, countryState$, hideOtherDataCountry$, hideViewTable$ } from '../../../redux/selectors';
import { calCacbonfootprint } from '../../../variables/calculator';
import DataEntry from './components/DataEntry';
import OtherDataIntro from './components/OtherDataIntro';
import TableCommon from './components/ProductionAndValue';
import SelectCountry from './components/SelectCountry';
import { viewDataEntryTitle1 } from 'variables/general';

export default function Dashboard() {
  const dispatch = useDispatch();
  const countries = useSelector(countryState$);
  const hideDataCountry = useSelector(hideOtherDataCountry$);
  const hideViewTable = useSelector(hideViewTable$);

  const countryInfo = useSelector(countryInfoState$);
  let cacbonFootPrintData = {};

  React.useEffect(() => {
    dispatch(action.getCountries.getCountryRequest());
    if (countryInfo) {
      cacbonFootPrintData = calCacbonfootprint(countryInfo);
    }
  }, [dispatch, countryInfo, hideViewTable]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "100px" }}>
      <OtherDataIntro title={"Use own data"} />

      <Grid
        templateColumns={{ sm: "1fr", lg: "repeat(2, 1fr)" }}
        gap='24px'
      >
        <SelectCountry
          country={countries}
          selected={countryInfo?.isocode} />
      </Grid>
      {(hideDataCountry !== false && !!cacbonFootPrintData) &&
        <Grid mt="24px">
          <Text p='22px' fontSize='lg' fontWeight="bold">Breastfeeding practices among infants aged under 6 months </Text>
          <DataEntry
            captions={viewDataEntryTitle1}
            data={calCacbonfootprint(countryInfo)}
            rows={dataEntryRow}
          />
          {
            (hideViewTable != undefined && hideViewTable !== false) && (
              <>
                <TableCommon
                  captions={viewDataEntryTitle}
                  data={calCacbonfootprint(countryInfo)}
                  rows={dataEntryRow}
                  type={1}
                />
                <Text p='22px' fontSize='lg' fontWeight="bold">Carbon and water footprints associated with consumption
                  of commercial milk formula among infants aged under 6 months</Text>
                <TableCommon
                  captions={viewCacbonFootPrintTitle}
                  data={calCacbonfootprint(countryInfo)}
                  rows={dataEntryRow}
                  type={2}
                />
              </>
            )
          }
        </Grid>
      }
    </Flex>
  );
}