// Chakra imports
import {
  Center,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import { average, financial, sum } from 'variables/calculator';

const TableCommon = ({ captions, data, rows, type = 1 }) => {
  const textColor = useColorModeValue("#89BD55", "#89BD55");
  const borderColor = useColorModeValue("#edf2f7", "#1f2733");

  return (
    <Card className='table-country-caculator' overflowX={{ sm: "scroll", xl: "hidden" }} mb="24px">
      <Table variant='simple' mb="20px">
        <Thead>
          <Tr>
            {type == 1 && captions.map((caption, idx) => {
              return (
                <Th key={idx} pl={idx == 0 ? '0px' : null} textTransform="none" textAlign="center" width={"30px"} >
                  <Center><Text fontSize="14px" color={textColor} >{caption.title}</Text></Center>
                  <Center><Text fontSize="14px" color={textColor} >{caption.note}</Text></Center>
                  {/* <Center height='16px'><Text fontSize="12px" fontWeight="light"> {caption.note}</Text></Center> */}
                </Th>
              );
            })}
            {
              type != 1 && (
                captions.map((caption, idx) => {
                  return (
                    idx !== 2 ? (
                      <Th key={idx} width={"15%"} minWidth={'180px'} textTransform="none">
                        <Center>
                          <Text fontSize="14px" color={textColor} >{caption.title}</Text>
                        </Center>
                        <Center>
                          <Text fontSize="14px" color={textColor} >{caption.note}</Text>
                        </Center>
                        {/* <Center height='16px'><Text fontSize="12px" fontWeight="light"> {caption.note}</Text></Center> */}
                      </Th>
                    ) : (
                      <Th width={"35%"} minWidth={'180px'} key={idx} textTransform="none">
                        <Text fontSize="14px" color={textColor} textAlign={"center"} pb={"10px"} borderBottom={`1px solid ${borderColor}`} >{caption.bigTitle}</Text>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th textTransform="none" borderBottom={"unset"}>
                                <Text fontSize="14px" color={textColor} textAlign={"center"} >{caption.title1}</Text></Th>
                              <Th textTransform="none" borderBottom={"unset"} textAlign={"center"}><Text fontSize="14px" color={textColor} >{caption.title2}</Text></Th>
                            </Tr>
                          </Thead>
                        </Table>
                      </Th>
                    ));
                })
              )
            }
          </Tr>
        </Thead>
        <Tbody>
          {type == 1 ?
            (
              data && rows.map((row, idx) => {
                const getKey = (idx) => {
                  switch (idx) {
                    case 0: return "month_0_1";
                    case 1: return "month_2_3";
                    case 2: return "month_4_5";
                    case 3: return "month_0_6";
                    default: return "test";
                  }
                }
                const key = getKey(idx);

                return (
                  <Tr key={idx} fontSize="14px">
                    <Td pl="0px"><Center>{row}</Center></Td>
                    <Td><Center>{financial(data.eBF[key] * 100, 1, '%')}</Center></Td>
                    <Td><Center>{financial(data.partialBF[key] * 100, 1, '%')}</Center></Td>
                    <Td><Center>{financial(data.notBF[key] * 100, 1, '%')}</Center></Td>
                    <Td><Center>{idx === 3 ? financial(data.noOfLivebirths, 0) : financial(data.noOfLivebirths / 3, 0)}</Center></Td>
                  </Tr>
                )
              })
            ) : (
              data && rows.map((row, idx) => {
                const getKey = (idx) => {
                  switch (idx) {
                    case 0: return "month_0_1";
                    case 1: return "month_2_3";
                    case 2: return "month_4_5";
                    case 3: return "month_0_6";
                    default: return "test";
                  }
                }
                const key = getKey(idx);

                return (
                  <Tr key={idx} fontSize="14px">
                    <Td pl="0px" width={"15%"} minWidth={'180px'} whiteSpace="nowrap"><Center>{row}</Center></Td>
                    <Td width={"25%"} minWidth={'180px'}><Center>{financial(data.lostMilk[key], 1)}</Center></Td>
                    <Td padding={"unset"}>
                      <Table>
                        <Tbody>
                          <Tr>
                            <Td borderBottom={"unset"} width={"15%"} minWidth={'180px'}><Center>{financial(data.lowCFP[key], 1)}</Center></Td>
                            <Td borderBottom={"unset"} width={"15%"} minWidth={'180px'}><Center>{financial(data.highCFP[key], 1)}</Center></Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Td>
                    <Td width={"25%"} minWidth={'180px'}><Center>{financial(data.amountWater[key], 1)}</Center></Td>
                  </Tr>
                )
              })
            )
          }
        </Tbody>
      </Table>
    </Card >
  );
};

export default TableCommon;
