
export const INIT_STATE = {
    countries: {
        data: [],
        isHide: false,
        isHideOtherData: false,
        isLoading: true,
        isDisableBtnSearch: true,
        isHideViewTable: false,
        ownData: {},
        counterfactorData: {}
    },
    countryInfo: {
        data: {},
        isHideDataInfo: null,
    }
}