// Chakra imports
import { Box, Grid, Heading, Image, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import img from "assets/img/OtherData.jpg";
import { otherDataOverView } from 'variables/general';

const OtherDataIntro = ({ title }) => {
  return (
    <Card mb="10px">
      <Grid spacing={16} justify="flex-start">
        <Grid order={{ sm: "2", lg: "2" }}  mb={{ sm: "26px" }}>
          <Heading as='h3' size='lg'>{title}</Heading>
        </Grid>
        <Grid
          order={{ sm: "1", lg: "1" }}
          sx={{ display: { lg: 'none', sm: 'block' } }}
          mt="20px"
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'
          mb={{ sm: "26px" }}>
          <Image src={img} alt='img2' order={{ sm: "1", lg: "1" }} sx={{ display: { sm: 'block', lg: 'none' } }} />
        </Grid>
      </Grid>
      <CardBody>
        <Grid
          templateColumns={{ sm: "1fr", lg: "0.4fr 1.6fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'>
          <Image src={img} alt='img2' sx={{ display: { sm: 'none', lg: 'block' } }} />
          <Box order={{ sm: "2", lg: "2" }} fontSize="15px">
            <Text>
              {otherDataOverView}
            </Text>
            <Text mt="10px">
              - Newer national data
              <br />
              - Subnational data
              <br />
              - Data from a group of countries
              <br />
              - Hypothetical data
            </Text>
          </Box>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default OtherDataIntro;
