export function calCacbonfootprint(data) {
  if (!data || data.length == 0) {
    return null;
  }
  const I3 = data.noOfLivebirths
  let ebf06Mos = average([data.ebf01Mos, data.ebf23Mos, data.ebf45Mos]) ?? 0;
  let eBF = {
    month_0_1: data.ebf01Mos,
    month_2_3: data.ebf23Mos,
    month_4_5: data.ebf45Mos,
    month_0_6: ebf06Mos
  };

  let partial06Mos = average([data.partial01Mos, data.partial23Mos, data.partial45Mos]) ?? 0;
  let partialBF = {
    month_0_1: data.partial01Mos,
    month_2_3: data.partial23Mos,
    month_4_5: data.partial45Mos,
    month_0_6: partial06Mos
  }

  let notBF06Mos = average([data.notBF01Mos, data.notBF23Mos, data.notBF45Mos]) ?? 0
  let notBF = {
    month_0_1: data.notBF01Mos,
    month_2_3: data.notBF23Mos,
    month_4_5: data.notBF45Mos,
    month_0_6: notBF06Mos
  }

  let calculates = [
    calculate(I3, data.ebf01Mos, data.partial01Mos, data.notBF01Mos, 1),
    calculate(I3, data.ebf23Mos, data.partial23Mos, data.notBF23Mos, 2),
    calculate(I3, data.ebf45Mos, data.partial45Mos, data.notBF45Mos, 3),
    calculate(I3, ebf06Mos, partial06Mos, notBF06Mos, 4)
  ]

  let lostMilk = {
    month_0_1: calculates[0].lostMilk,
    month_2_3: calculates[1].lostMilk,
    month_4_5: calculates[2].lostMilk,
    month_0_6: sum([calculates[0].lostMilk + calculates[1].lostMilk + calculates[2].lostMilk])
  }

  let lowCFP = {
    month_0_1: calculates[0].lowCFP,
    month_2_3: calculates[1].lowCFP,
    month_4_5: calculates[2].lowCFP,
    month_0_6: sum([calculates[0].lowCFP + calculates[1].lowCFP + calculates[2].lowCFP])
  }

  let highCFP = {
    month_0_1: calculates[0].highCFP,
    month_2_3: calculates[1].highCFP,
    month_4_5: calculates[2].highCFP,
    month_0_6: sum([calculates[0].highCFP + calculates[1].highCFP + calculates[2].highCFP])
  }

  let amountWater = {
    month_0_1: calculates[0].amountWater,
    month_2_3: calculates[1].amountWater,
    month_4_5: calculates[2].amountWater,
    month_0_6: sum([calculates[0].amountWater, calculates[1].amountWater, calculates[2].amountWater])
  }

  return {
    eBF,
    partialBF,
    notBF,
    lostMilk,
    lowCFP,
    highCFP,
    noOfLivebirths: I3,
    amountWater
  }
}

export function financial(x, number = 3, postfix = '') {
  if (!x ||x == 0 || x== '0') {
    return '-';
  }
  let num = Number(Math.round(Math.pow(10, number) * x) / Math.pow(10, number));
  if(num == 0)  return '-';
  return num.toLocaleString('en-US') + postfix;
}

export function financialReturn0(x, number = 3, postfix = '') {
  let num = Number(Math.round(Math.pow(10, number) * x) / Math.pow(10, number));
  return num.toLocaleString('en-US') + postfix;
}

export function sum(data) {
  if (!data) {
    return 0;
  }
  return data.reduce((partialSum, a) => partialSum + a, 0);
}

export function average(data) {
  if (!data) {
    return 0;
  }
  return sum(data) / data.length;
}

export function calculate(liveBirths = 0, eBF = 0, partialBF = 0, notBF = 0, type = 1) {
  const milion = 1000000;
  const I3 = liveBirths;
  const C7 = eBF;
  const D7 = partialBF;
  const E7 = notBF;
  const G7 = I3;
  const AG7 = 11;
  const AB7 = 20 / 6 * 2;
  const F7 = 1;
  const I7 = G7 * D7;
  const AK7 = 14;
  let AC7 = (6.7 / 6) * 2;
  const AE7 = AC7 * I7;

  const L = {
    L71: (0.68 + 0.71) / 2,
    L72: (0.73 + 0.76) / 2,
    L73: (0.77 + 0.83) / 2,
  };
  const M = {
    M71: (0.59 + 0.68) / 2,
    M72: (0.71 + 0.68) / 2,
    M73: (0.69 + 0.59) / 2
  }
  let L7 = (type === 1) ? L.L71 : (type === 2 ? L.L72 : (type === 3 ? L.L73 : L.L74));
  let M7 = (type === 1) ? M.M71 : (type === 2 ? M.M72 : (type === 3 ? M.M73 : M.M74));

  let O7 = M7 * 30 * 2
  let Q7 = (O7 * I7);

  const K7 = G7 * F7;
  let N7 = L7 * 30 * 2;
  const H7 = G7 * C7;
  const P7 = N7 * H7;
  const R7 = P7 + Q7;
  const S7 = N7 * K7;


  let lostMilk = (S7 - R7) / milion;

  const J7 = G7 * E7;
  const AD7 = AB7 * J7;

  let lowCFP = (AG7 * (AB7 * J7 + AC7 * I7)) / milion;

  let highCFP = (AK7 * G7 * (AB7 * E7 + AC7 * D7)) / milion;

  const AF7 = AC7 * I7;

  let amountWater = ((6.7 + 6 + 4745) * (AD7 + AE7)) / milion;
  return {
    lostMilk,
    lowCFP,
    highCFP,
    amountWater
  }
}

export function calculateEntryData(ownData, counterfactorData) {
  const milion = 1000000;
  // const I3 = liveBirths;
  const C11 = counterfactorData.ex / 100;
  const D11 = counterfactorData.pb / 100;
  const E11 = counterfactorData.nb / 100;

  const C10 = ownData.ex / 100;
  const D10 = ownData.pb / 100;
  const E10 = ownData.nb / 100;

  const L11 = 0.75;
  const L10 = 0.75;
  const G11 = counterfactorData.liveBirth;
  const G10 = ownData.liveBirth;

  const F10 = 1;
  const F11 = 1;

  const M11 = 0.66;
  const M10 = 0.66;

  const N11 = L11 * 30 * 2;
  const O11 = M11 * 30 * 2;
  const N10 = L10 * 30 * 2;

  const H11 = G11 * C11;
  const I11 = G11 * D11;
  const I10 = G10 * D10;


  //lost milk for counterfactorData
  const T11 = ((N11 * G11 * F11) - ((N11 * H11) + O11 * I11)) / milion;

  const AB10 = 20;
  const AC10 = 6.7;
  const AG10 = 11;
  const J10 = G10 * E10;
  const AD10 = AB10 * J10;
  const AE10 = AC10 * I10;

  // lostmilk for ownData

  const O10 = M10 * 30 * 2;
  const H10 = G10 * C10;
  const T10 = (N10 * G10 * F10 - (N10 * H10 + O10 * I10))/milion;

  //low CFP for ownData
  const AJ10 = (AG10 * AD10 + AG10 * AE10) / milion

  const AK10 = 14;

  //high CFP for ownData
  const AN10 = (AK10 * AD10 + AK10 * AE10) / milion;
  const AF10 = AB10 * J10 + AC10 * I10;

  const BG10 = 4700 * AF10;
  const BF10 = 46 * AF10;
  const BE10 = 6.7 * AF10;

  //water for ownData
  const BH10 = (BE10 + BF10 + BG10) / milion;

  const AG11 = 11;
  const J11 = G11 * E11;
  const AB11 = AB10;
  const AD11 = AB11 * J11;
  const AC11 = 6.7;
  const AE11 = AC11 * I11;

  // low CFP for counterfactorData
  const AJ11 = (AG11 * AD11 + AG11 * AE11) / milion;

  // high CFP for counterfactorData
  const AK11 = 14;
  const AN11 = (AK11 * AD11 + AK11 * AE11) / milion;

  const AF11 = AB11 * J11 + AC11 * I11;
  const BG11 = 4700 * AF11;
  const BF11 = 46 * AF11;
  const BE11 = 6.7 * AF11;

  //water for counterfactorData
  const BH11 = (BE11 + BF11 + BG11) / milion;

  return {
    lostMilkOwnData: T10,
    lostMilkCounterfactorData: T11,
    lowCFPOwnData: AJ10,
    highCFPOwnData: AN10,
    amountWaterOwnData: BH10,
    lowCFPCounterfactorData: AJ11,
    highCFPCounterfactorData: AN11,
    amountWaterCounterfactorData: BH11
  }
}