import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import reducers from './redux/reducers/index.js';
import mySaga from './redux/sagas/index.js'
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga'
import {createStore,applyMiddleware} from 'redux';
import i18n from "./locales/index";
import { I18nextProvider } from "react-i18next";

import AdminLayout from "./layouts/Admin.js";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducers,applyMiddleware(sagaMiddleware));

sagaMiddleware.run(mySaga);

export default sagaMiddleware

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
       <HashRouter>
          <Switch> 
            <Route path={`/`} component={AdminLayout} />
            {/* <Redirect from={`/`} to="/admin/introduction" /> */}
          </Switch>
        </HashRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);