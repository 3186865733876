import {
  Grid
} from "@chakra-ui/react";
import Capture1 from "assets/img/CountryCalculator.jpg";
import OtherData from "assets/img/OtherData.jpg";
import Resource from "assets/img/Resource.jpg";
import Feedback from "assets/img/Feedback.jpg";
import AboutToolItem from "./components/AboutToolItem";
import CountryCaculatorItem from "./components/CountryCaculatorItem";
import ExperienceAndperspective from "./components/ExperienceAndperspective";
import InfoTool from "./components/InfoTool";
import OtherDataItem from './components/OtherDataItem';

export default function Dashboard() {
  return (
    <Grid
      mt="100px"
      gap='24px'
      mb={{ lg: "26px" }}>
      <InfoTool />
      <Grid
        templateColumns={{ sm: "1fr", lg: "1fr 1fr" }}
        templateRows={{ sm: "1fr", lg: "1fr" }}
        gap='24px'>
        <CountryCaculatorItem title="Calculate" link="#/preloaded-data" img={Capture1} />
        <OtherDataItem title="Use own data" link="#/data-entry" img={OtherData} />
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
        templateRows={{ sm: "1fr", lg: "1fr" }}
        gap='24px'
        mb={{ lg: "26px" }}>
        <AboutToolItem title="Resources" link="#/" img={Resource} />
        <ExperienceAndperspective title="Feedback" link="#/" img={Feedback} />
      </Grid>
    </Grid>
  );
}
