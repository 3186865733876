import {createActions, createAction} from 'redux-actions';

export const getType = (reduxAction) =>{
    return reduxAction().type;
}

//countries
export const getCountries = createActions({
    getCountryRequest: undefined,
    getCountrySuccess: (payload) => payload,
    getCountryError: (err) => err,
})

export const getCountryInfo = createActions({
    getCountryInfoRequest: (payload) => payload,
    getCountryInfoSuccess: (payload) => payload,
    getCountryInfoError: (err) => err,
})

export const addOwnData = createAction('ADD_OWN_DATA');
export const counterfactorData = createAction('COUNTERFACTOR_DATA');

export const isShow = createAction('IS_SHOW');
export const isHide = createAction('IS_HIDE');

export const isShowOtherData = createAction('IS_SHOW_OTHER_DATA');
export const isHideOtherData = createAction('IS_HIDE_OTHER_DATA');

export const isShowViewTable = createAction('IS_SHOW_VIEW_TABLE');
export const isHideViewTable = createAction('IS_HIDE_VIEW_TABLE');

export const isLoading = createAction('IS_LOADING');