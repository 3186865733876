import { INIT_STATE } from "../../constants.js";
import { getCountries, getType, isShow, isHide, isLoading, isHideOtherData, isShowOtherData, addOwnData, counterfactorData, isShowViewTable, isHideViewTable } from "../actions";

export default function countryReducer(state = INIT_STATE.countries, action) {
  switch (action.type) {
    case getType(getCountries.getCountryRequest):
      return {
        ...state,
        isDisableBtnSearch: true
      };
    case getType(getCountries.getCountrySuccess):
      return {
        ...state,
        data: action.payload,
        isDisableBtnSearch: false
      };
    case getType(getCountries.getCountryError):
      return {
        ...state,
      };
    case getType(isShow):
      return {
        ...state,
        isHide: true,
      };
    case getType(isHide):
      return {
        ...state,
        isHide: false,
      };
    case getType(isShowOtherData):
      return {
        ...state,
        isHideOtherData: true,
      }
    case getType(isHideOtherData):
      return {
        ...state,
        isHideOtherData: false,
      }
    case getType(addOwnData): {
      return {
        ...state,
        ownData: action.payload,
      }
    }
    case getType(counterfactorData): {
      return {
        ...state,
        counterfactorData: action.payload,
      }
    }
    case getType(isShowViewTable):
      return {
        ...state,
        isHideViewTable: true,
      };
    case getType(isHideViewTable):
      return {
        ...state,
        isHideViewTable: false,
      };
    case getType(isLoading):
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    default:
      return state;
  }
}
