// Chakra imports
import { Button, Grid, Select, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../../redux/actions';
import { isDisableBtnSearch$, isHideDataInfo$ } from '../../../../redux/selectors';


const SelectCountry = ({ country, selected }) => {

  const dispatch = useDispatch();
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const isHideDataInfo = useSelector(isHideDataInfo$);

  // const hideDataCountry = useSelector(hideDataCountry$);
  const isDisableBtnSearch = useSelector(isDisableBtnSearch$);
  const onClickSearch = React.useCallback((e) => {
    let ISO = e.currentTarget.value;
    if (ISO == '') {
      return;
    };
    dispatch(action.getCountryInfo.getCountryInfoRequest(ISO));
    dispatch(action.isShow());
  }, [dispatch]);

  return (
    <Card id="select-country-caculator" mt="10px" mb={{ sm: "26px", lg: "0px" }}>
      <CardHeader>
        <Text color={textColor} fontWeight='bold' pb='.5rem'>
          {t("label.selectCountry")}
        </Text>
      </CardHeader>
      <CardBody mt="10px">
        <Grid
          templateColumns={{ sm: "1fr", lg: "1fr 1fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'>
          <Select
            disabled={isDisableBtnSearch}
            placeholder='Select Country'
            h='40px'
            minW="200px"
            mr="10px"
            onChange={(e) => onClickSearch(e)}
            fontSize="14px">
            {country?.map((value, id) => {
              return (
                <option key={id} value={value.ISO}>{value.Country}</option>
              );
            })}
          </Select>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default SelectCountry;
